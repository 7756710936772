import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Menu from "@components/Menu"
import Image from "../image"
import MissionStatement from "@components/MissionStatement"

const Header = ({ imageSrc, halfHeight }) => {
  return (
    <>
      <Menu />
      <div class="relative overflow-hidden">
        <div
          style={{ height: "100vh" }}
          class="max-w-7xl mx-auto relative md:h-screen pb-92"
        >
          <div class="relative z-30 pb-8 sm:pb-16 md:pb-20 lg:w-full lg:pb-28 xl:pb-32">
            <main class="mt-20 mx-auto max-w-7xl px-10 sm:mt-12 md:mt-16 lg:mt-20 lg:px-8 xl:mt-32">
              <div class="sm:text-center lg:text-center">
                <h1 class="text-6xl font-extrabold tracking-wide text-gray-800 sm:text-6xl">
                  <span class="text-7xl block text-white font-display xl:inline">
                    CleanUp
                  </span>
                </h1>
                <p class="mt-3 mb-32 text-base text-white font-sans italic sm:text-lg sm:max-w-xl sm:mx-auto md:text-xl xl:text-2xl">
                  ...for a cleaner, tidier Kent
                </p>
              </div>
            </main>
          </div>
        </div>

        <StaticImage
          className="absolute top-0 left-0 right-0 bottom-0"
          src="../../images/5.jpg"
          placeholder="blurred"
          layout="fullWidth"
          quality="100"
          alt="Sevenoaks landscape"
        />

        <div class="absolute left-0 right-0 -bottom-5 z-20">
          <MissionStatement />
        </div>
      </div>
    </>
  )
}

export default Header
